<template>
  <div id="ProducerShowsList">
    <QsCard :qlik-ids="['QweBL']" card-style="compact" />

    <QsTabs
      :qlik-ids="['mmJCq', '3b38ef81-d831-442c-abef-926fc19f63d9']"
      :date="{ field: 'Jour du spectacle' }"
    />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";
import QsTabs from "@/components/Qlik/QsTabs";

export default { components: { QsCard, QsTabs } };
</script>

<style scoped>
#ProducerShowsList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 45px repeat(1, 600px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
